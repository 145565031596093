class WindowParameters
{
    constructor()
    {
        this.type = 0;
        this.positionX = 0.2;
        this.positionY = 1.3;
        this.wall = 0;
    }
}

export default WindowParameters;