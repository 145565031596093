import React, { useState} from 'react'
import { useDispatch } from 'react-redux'
import {changeProperty} from '../../actions'
import SettingsItem from './SettingsItem'
import SliderInputDimensions from '../inputs/SliderInputDimensions'
import ImageListInput from '../inputs/ImageListInput'
import MaterialInput from '../inputs/MaterialInput'
import { RoofTypes } from '../../logic/RoofTypes' 

function Dimensions() {
    const dispatch = useDispatch();

    const handleWidthChange = (value) => {
        dispatch(changeProperty("garage", "width", value));
    }

    const handleLengthChange = (value) => {
        dispatch(changeProperty("garage", "length", value));
    }

    const handleHeightChange = (value) => {
      dispatch(changeProperty("garage", "height", value));
  }

  const handleTypeChange = (id) => {
    dispatch(changeProperty("roof", "type", id))
    dispatch(changeProperty("roof", "materialType", 0));
}

    const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
    dispatch(changeProperty("garage", "insulation", !checked));
  };

  const handleChange2 = () => {
    setChecked(!checked);
    dispatch(changeProperty("roof", "insulation", !checked));
  };

    return (
        <div className="Dimensions">
            <SettingsItem header="Stavba">
            <div style={{display: "flex"}}>
                <label>
        <input type="checkbox" checked={checked}
          onChange={handleChange} />
        <b>Zateplení (Izolace stěn a stropu)</b>
      </label>
                </div>       
                <div style={{display: "flex"}}>
                <SliderInputDimensions
                    label="Šířka"
                    unit="m"
                    values={[2.5,2.7,2.9,3.1,3.5,4.0,5.0,5.4,5.8]}
                    value="2"
                    step="0.5"
                    onChange={handleWidthChange}
                    >
                    </SliderInputDimensions>
                    
                    </div>
                    <div style={{display: "flex"}}>

                    <SliderInputDimensions
                    label="Délka"
                    unit="m"
                    min="5.2"
                    max="9"
                    values={[5.02,5.2,5.4,5.86,6.86,7.86,8.74]}
                    step="0.2"
                    onChange={handleLengthChange}
                    >
                    </SliderInputDimensions>
                    </div>
                    {/* <div style={{display: "flex"}}>

                    <SliderInputDimensions
                    label="Limit výška*"
                    unit="m"
                    min="5.2"
                    max="9"
                    values={[2.2,2.29,2.42,2.54,2.7]}
                    step="0.2"
                    onChange={handleHeightChange}
                    value="4"
                    >
                    </SliderInputDimensions>
                    </div>*/}

                    <div style={{display: "flex"}}> 
                    <ImageListInput
                    label="Typ střechy"
                    items={RoofTypes}
                    onChange={handleTypeChange}
                ></ImageListInput>
                    </div>
                    <h5>*Limit výšky určuje jaké je vaše maximum pro výšku stavby pro vámi zvolený rozměr. Neurčuje výslednou výšku stavby.</h5>
            </SettingsItem>
        </div>
    )
}

export default Dimensions
