import React from 'react'
import dropdown from '../../imgs/send.png'
import Swal from 'sweetalert2'
import emailjs from '@emailjs/browser';
import { useSelector } from 'react-redux';

function Contact() {

  const parameters = useSelector(state => state.parameters);

    async function openModal() {
      const { value: formValues } = await Swal.fire({
        title: 'Poptat Zvolenou Konfiguraci',
        html:
          '<div style="font-size:14px">Jste jen krůček od vaší nezávazné cenové nabídky. Už jen pár osobních údajů a individuální cenovou nabídku od nás dostanete do 24 hodin.</div> <input placeholder="Jméno / Firma*" id="swal-input-name" class="swal2-input">' +
          '<input placeholder="Telefon*" id="swal-input-phone" class="swal2-input">' +
          '<input placeholder="E-mail*" id="swal-input-mail" class="swal2-input">' +
          '<input placeholder="Vaše Lokalita" id="swal-input-local" class="swal2-input">'+
          '<input rows="4" cols="50" placeholder="Poznámka" id="swal-input-note" class="swal2-input">',
        focusConfirm: false,
        confirmButtonText: "Odeslat Poptávku",
        preConfirm: () => {
          let name = document.getElementById('swal-input-name').value
          let phone = document.getElementById('swal-input-phone').value
          let mail = document.getElementById('swal-input-mail').value
          console.log(name)
          if (name.length < 1 && phone.length < 1 && phone.length < 1) {
          Swal.showValidationMessage(
            `Prosím zadejte všechny požadované informace (Minimálně: Jméno, Mail, Telefon) před odesláním poptávky !`
          )
          }
          else
            return [
              document.getElementById('swal-input-name').value,
              document.getElementById('swal-input-phone').value,
              document.getElementById('swal-input-mail').value,
              document.getElementById('swal-input-local').value,
              document.getElementById('swal-input-note').value
            ]          
        }
      })

      if(formValues){

        Swal.fire({title: "Zpracovávám a Odesílám...", didOpen: () => {
          Swal.showLoading()}})

          const basicData = {
            delka: parameters.garage.length,
            sirka: parameters.garage.width,
            zatepleni: (parameters.garage?.insulation == true ? "Ano" : "Ne"),
            strecha: (parameters.roof?.type == 4 ? "Pultova" : "Sedlova"),
            omitka_barva: parameters.garage.material,
            strecha_barva: parameters.roof.material
          }

          const gateData = {
            typ: (parameters.gate?.type == 0 ? "Vyklopna" : "Sekcni"),
            sirka: parameters.gate?.width,
            zatepleni: (parameters.gate?.insulation == true ? "Ano" : "Ne"),
            pohon: (parameters.gate?.motor == 0 ? "Bez Pohonu" : "ProMatic/SupraMatic"),
            typ: (parameters.gate?.model == 0 ? "Renomatic M" : "Renomatic L"),
          }

          let doorData = 0
          let doorInsulated = 0
          parameters.doors.forEach(element => {
            doorData++
            if (element.insulation)
              doorInsulated++
          });


          let windowData = 0
          let windowType = []
          parameters.windows.forEach(element => {
            windowData++
            switch (element.type) {
              case 0:
                windowType.push("800x600 - Okno")
                break
              case 1:
                windowType.push("1000x800 - Okno")
                break
              case 2:
                windowType.push("350x1700 - Svetlik")
                break
            }
          });


          const templateParams = {
            name: formValues[0],
            phone: formValues[1],
            mail:formValues[2],
            local: formValues[3],
            note: formValues[4],
            parameters: JSON.stringify(parameters),
            building: JSON.stringify(window.building),
            extras: window.extras,
            sizes: JSON.stringify(parameters.garage),
            roof: JSON.stringify(parameters.roof),
            gate: JSON.stringify(parameters.gate),
            doors: JSON.stringify({pocet: doorData, pocet_zateplenych: doorInsulated}),
            windows: JSON.stringify({pocet: windowData, typy: windowType}),
            basic: JSON.stringify(basicData),
            basicGate: JSON.stringify(gateData),
            price: window.calculatedPrice
        };
        emailjs.send('service_gka3oqp', 'template_iwxqwnq', templateParams, 'brte1C9alMcj7KiOe')
            .then((response) => {
              Swal.close()
              setTimeout(() => {
                window.location.replace(
                  "https://fabrego.cz/dekujeme/"
                );
              });
            }, (err) => {
              Swal.close()
              Swal.fire("Problém při odesílání poptávky, zkuste to prosím znovu.")
            });
    }
    }
  
    return (
      <a href="#">
        <div onClick={() =>{openModal()}} className="Contact">
        <div className="SettingsItem--send">
            <div className="SettingsItem--header">
                <div onClick={() =>{openModal()}}className="SettingsItem--header-text">Odeslat Poptávku</div>
                <img 
                    className="SettingsItem--header-dropdown" 
                    src={dropdown}
                    alt="dropdown"
                    width="20"
                ></img>
            </div>   
        </div>
        </div>
        </a>
    )
}

export default Contact
