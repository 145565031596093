class DoorParameters
{
    constructor()
    {
        this.materialType = 0;
        this.material = 0;
        this.handlePosition = 0;
        this.position = 0.2;
        this.wall = 0;
    }
}

export default DoorParameters;