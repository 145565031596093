export const SPACE = 0.2;
export const DOOR_WIDTH = 0.8;
export const DOOR_HEIGHT = 1.9;
export const CANVAS_BACKGROUND = 0xffffff;
export const ROOF_MARGIN = 25;
export const THICKNESS = 2;
export const BORDER_COLOR = 0x111111;
export const WINDOW_COLOR = 0xd8fbf2;
export const CAMERA_DEFAULT_POSITION = {
    X: 250,
    Y: 400,
    Z: 650
}
export const ORBIT_CONTROLS_TARGET = {
    X: 0,
    Y: 0,
    Z: 0
}

export const ROOF_TYPES = {
    LEFT: 0,
    RIGHT: 1,
    GABLE: 2,
    FRONT: 3,
    BACK: 4
};
export const MATERIAL_TYPES = {
    GALVANIZED: 0,
    MAT: 1,
    SHINY: 2,
    WOODLIKE: 3
}
export const GATE_TYPES = {
    DOUBLE_LEAF: 0,
    UP_AND_OVER: 1,
    NONE: 2
};
export const GATE_POSITION = {
    LEFT: 0,
    CENTER: 1,
    RIGHT: 2
};
export const WALLS = {
    LEFT: 0,
    RIGHT: 1,
    FRONT: 2,
    BACK: 3
}
export const WINDOW_TYPES = {
    SMALL: 0,
}
export const WINDOW_SIZES = [
    { WIDTH: 80, HEIGHT: 60},
    { WIDTH: 100, HEIGHT: 80},
    { WIDTH: 40, HEIGHT: 170}
]
export const COLORS = [
    [
        0xa1a1a1
    ],
    [
        0x873e23, 0xfedc9f,
        0xBFBAB9, 0xB05B24,
        0xF3E7E0, 0x8F6845,
        0x3A332C, 0xEAA93A,
        0x86CA70
    ],
    [
        0x151515, 0xE9E8E8, 0xD3D3D3, 0x070707, 0x88421C,
        0x8F351B, 0x1B518F, 0xC3A622, 0xC8875A, 0x892200,
        0x166900
    ],
    [
        0x151515, 0xE9E8E8, 0xD3D3D3, 0x070707, 0x88421C,
        0x8F351B, 0x1B518F, 0xC3A622, 0xC8875A, 0x892200,
        0x166900
    ],
    [
        0xffffff,
        0xffffff,
    ]
];
export const WARNINGS = {
    GATE_WIDTH: "Šířka brány je větší než šířka garáže",
    GATE_HEIGHT: "Výška brány je větší než minimální výška garáže",
    NO_ENTRY: "Žádný vstup. Přidejte bránu nebo dveře",
    GARAGE_HEIGHT: "Minimální výška je větší než maximální výška",
    DOOR_GATE_OVERLAP: "Dveře se překrývají s bránou",
    DOOR_WINDOW_OVERLAP: "Dveře se překrývají s oknem",
    DOOR_DOOR_OVERLAP: "Dveře se překrývají",
    WINDOW_GATE_OVERLAP: "Window is overlapping with gate",
    WINDOW_WINDOW_OVERLAP: "Okno se překrývá s bránou",
    DOOR_OUTSIDE: "Vrata jsou mimo garáž",
    WINDOW_OUTSIDE: "Okna jsou mimo garáž nebo příliš blízko střechy",
}