import React from 'react'
import Dimensions from './settings-items/Dimensions'
import Gate from './settings-items/Gate'
import Heights from './settings-items/Heights'
import Roof from './settings-items/Roof'
import Walls from './settings-items/Walls'
import Doors from './settings-items/Doors'
import Windows from './settings-items/Windows'
import Contact from './settings-items/Contact'
import Additions from './settings-items/Additions'
import './Settings.css'



function Settings(props) {
    return (
        <div className="Settings">
            <center> <img style={{ "padding-top": 20, "width": "200px" }} src="https://fabrego.cz/storage/2021/11/logo-1.png"></img></center>
            <div style={{marginLeft: "20px"}}>
            {/* <h5>Konfigurátor slouží k vypracování pouze <b>přibližné</b> ceny navrhované stavby. Nejedná se o finalizovanou nabídku, ta vám bude vypracována obratem.</h5> */}
            </div>
            <Dimensions></Dimensions>
            <Walls></Walls>
            <Roof></Roof>
            {/* <Heights garage={props.parameters.garage}></Heights> */}
            <Gate garage={props.parameters.garage}></Gate>
            <Doors doors={props.parameters.doors} garage={props.parameters.garage}></Doors>
            <Windows windows={props.parameters.windows} garage={props.parameters.garage}></Windows>
            <Contact></Contact>
        </div>
    )
}

export default Settings
