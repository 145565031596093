import React, { useState } from 'react'
import './SliderInput.css'

function SliderInputDimensions(props) {
    const [value, setValue] = useState(props.value || 0);

    const handleChange = (e) => {
        const v = parseFloat(e.currentTarget.value);
        setValue(v);
        props.onChange(props.values[v]);
    }

    return (
        <div className="SliderInput">
            <label className="SliderInput--label">{props.label}</label>
            <input 
                type="range" 
                min={0}
                max={props.values?.length - 1 || 10}
                step={1}
                onChange={handleChange}
                value={value}
            ></input>
            <div className="SliderInput--value">{props.values[value] || 10}{props.unit}  ({props.values[value] * 1000} mm)</div>
        </div>
    )
}

export default SliderInputDimensions
