import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeProperty } from '../../actions'
import ImageListInput from '../inputs/ImageListInput'
import MaterialInput from '../inputs/MaterialInput'
import SettingsItem from './SettingsItem'
import { RoofTypes } from '../../logic/RoofTypes' 
import { useSelector } from 'react-redux';

function Roof() {
    const parameters = useSelector(state => state.parameters);
    const dispatch = useDispatch();
    const [type, setType] = useState("");

    // const handleTypeChange = (id) => {
    //     dispatch(changeProperty("roof", "type", id))
    //     setType(id)
    //     dispatch(changeProperty("roof", "materialType", 0));
    // }

    const handleMaterialChange = (type, color) => {
        dispatch(changeProperty("roof", "materialType", type));
        dispatch(changeProperty("roof", "material", color));
    }    


    const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
    dispatch(changeProperty("roof", "insulation", !checked));
  };

    return (
        <div className="Roof">
            <SettingsItem header="Střecha, Atika">
             {parameters.roof.type == 2 &&
            <h5>Nátěr je v případě sedlové střechy v ceně.</h5>
             }

            {parameters.roof.type == 4 &&
            <h5>U pultových střech je cena nátěru 90kč/m².</h5>
            }


                {/* <ImageListInput
                    label="Typ střechy"
                    items={RoofTypes}
                    onChange={handleTypeChange}
                ></ImageListInput> */}
                <MaterialInput
                label="Barva atiky"
                    typeValue={parameters.roof.type == 2 ? 2 : 3}
                    key={parameters.roof.type}
                    valuesIndex={parameters.roof.type == 2 ? 2 : 3}
                    onChange={handleMaterialChange}
                ></MaterialInput>

                <h5>Volba barvy atiky je v ceně (4 různé barvy) a probíhá až u finální objednávky.</h5>
            </SettingsItem>
        </div>
    )
}

export default Roof
