import React, {useEffect, useState} from 'react'
import './WindowInput.css'
import SelectInput from './SelectInput'
import SliderInput from './SliderInput'
import remove from '../../imgs/ui/remove.png'
import Row from './Row'
import {WALLS, SPACE, WINDOW_SIZES} from '../../logic/Constants'

function WindowInput(props) {
    const [maxPosition, setMaxPosition] = useState({
        X: 2,
        Y: 2
    });

    useEffect(() => {
        const side = [WALLS.LEFT, WALLS.RIGHT].includes(props.params.wall) ? 
            props.garage.length : props.garage.width;
            
        setMaxPosition({
            X: parseFloat((side - WINDOW_SIZES[props.params.type].WIDTH*0.01 - SPACE).toFixed(1)),
            Y: parseFloat((props.garage.height_min - WINDOW_SIZES[props.params.type].HEIGHT*0.01 - SPACE).toFixed(1))
        });
    }, [props.garage, props.params, setMaxPosition]);

    const typeOptions = ["Okno (800x600mm)", "Okno (1000x800mm)", "Světlík (350x1700mm)" ].map((type, i) => {
        return { value: i, text: type }
    })

    const wallOptions = ["Levá", "Pravá", "Přední", "Zadní"].map((wall, i) => {
        return { value: i, text: wall }
    })

    const handleTypeChange = (value) => {
        props.onItemChange(props.index, "type", value);
        console.log("TYPE CHANGE !!!")

        if(value == 2)
            props.onItemChange(props.index, "positionY", 0.1);
        else
            props.onItemChange(props.index, "positionY", 1.0); 
    }

    const handleWallChange = (value) => {
        props.onItemChange(props.index, "wall", value);
    }

    const handlePositionXChange = (value) => {
        props.onItemChange(props.index, "positionX", value);
    }   
    
    const handlePositionYChange = (value) => {
        props.onItemChange(props.index, "positionY", value);
    }  

    const handleRemove = () => {
        props.onItemRemove(props.index);
    }

    return (
        <div className="WindowInput">
            <div className="WindowInput--remove" onClick={handleRemove}>
                <img src={remove} alt="remove"></img>
            </div>
            <Row>
                <SelectInput
                    label="Typ"
                    options={typeOptions}
                    value={props.params.type}
                    onChange={handleTypeChange}
                ></SelectInput>
                <SelectInput
                    label="Stěna"
                    options={wallOptions}
                    value={props.params.wall}
                    onChange={handleWallChange}
                ></SelectInput>
            </Row>
            <SliderInput
                label="Horizontálně"
                unit="m"
                min={0.2}
                max={maxPosition.X}
                step={0.1}
                value={props.params.positionX} 
                onChange={handlePositionXChange}
            ></SliderInput>
            {/* <SliderInput
                label="Vertikálně"
                unit="m"
                min={0.1}
                max={maxPosition.Y}
                step={0.1}
                value={props.params.positionY} 
                onChange={handlePositionYChange}
            ></SliderInput> */}
            <h5>Min. 200 mm od kraje, ideálně ve výšce 1300 mm od spodního okraje stěny.</h5>
        </div>
    )
}

export default WindowInput