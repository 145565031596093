import hneda_tmava from "../imgs/omitky/hneda_tmava.png"
import piskova from "../imgs/omitky/piskova.png"
import seda from "../imgs/omitky/seda.png"
import cihla from "../imgs/omitky/cihla.png"
import bila from "../imgs/omitky/bila.png"
import bezova from "../imgs/omitky/bezova.png"
import antracit from "../imgs/omitky/antracit.png"
import zlata_kukurice from "../imgs/omitky/zlata_kukurice.png"
import zelena from "../imgs/omitky/zelena.png"

import strecha1_antracit from "../imgs/sedlovestrechy/antracit.png"
import strecha1_bila from "../imgs/sedlovestrechy/bila.png"
import strecha1_bilyhlinik from "../imgs/sedlovestrechy/bilyhlinik.png"
import strecha1_cerna from "../imgs/sedlovestrechy/cerna.png"
import strecha1_hneda from "../imgs/sedlovestrechy/hneda.png"
import strecha1_hnedocervena from "../imgs/sedlovestrechy/hnedocervena.png"
import strecha1_modra from "../imgs/sedlovestrechy/modra.png"
import strecha1_piskova from "../imgs/sedlovestrechy/piskova.png"
import strecha1_sedohneda from "../imgs/sedlovestrechy/sedohneda.png"
import strecha1_vinova from "../imgs/sedlovestrechy/vinova.png"
import strecha1_zelena from "../imgs/sedlovestrechy/zelena.png"


import strecha2_antracit from "../imgs/pultovestrechy/antracit.png"
import strecha2_bila from "../imgs/pultovestrechy/bila.png"
import strecha2_hneda from "../imgs/pultovestrechy/hneda.png"
import strecha2_hnedatmava from "../imgs/pultovestrechy/hnedatmava.png"
import strecha2_hnedacervena from "../imgs/pultovestrechy/hnedcervena.png"
import strecha2_modra from "../imgs/pultovestrechy/modra.png"
import strecha2_piskova from "../imgs/pultovestrechy/piskova.png"
import strecha2_sedobila from "../imgs/pultovestrechy/sedobila.png"
import strecha2_sedohneda from "../imgs/pultovestrechy/sedohneda.png"
import strecha2_stribrna from "../imgs/pultovestrechy/stribrna.png"
import strecha2_vinova from "../imgs/pultovestrechy/vinova.png"
import strecha2_zelena from "../imgs/pultovestrechy/zelena.png"

import dark from '../imgs/wallTypes/dark.png';
import bright from '../imgs/wallTypes/bright.png';


export const WallTypes = [
    [],
    [
        {id:0, src:hneda_tmava, caption:"Tmavě Hnědá"},
        {id:1, src:piskova, caption:"Písková"},
        {id:2, src:seda, caption:"Šedá"},
        {id:3, src:cihla, caption:"Cihla"},
        {id:4, src:bila, caption:"Bilá"},
        {id:5, src:bezova, caption:"Béžová"},
        {id:6, src:antracit, caption:"Antracit"},
        {id:7, src:zlata_kukurice, caption:"Zlatá Kukuřice"},
        {id:8, src:zelena, caption:"Zelená"},
    ],
    [
        {id:0, src:strecha1_antracit, caption:"Antracit"},
        {id:1, src:strecha1_bila, caption:"Bilá"},
        {id:2, src:strecha1_bilyhlinik, caption:"Bilý Hliník"},
        {id:3, src:strecha1_cerna, caption:"Černá"},
        {id:4, src:strecha1_hneda, caption:"Hnědá"},
        {id:5, src:strecha1_hnedocervena, caption:"Hnědočervená"},
        {id:6, src:strecha1_modra, caption:"Modrá"},
        {id:7, src:strecha1_piskova, caption:"Písková"},
        {id:8, src:strecha1_sedohneda, caption:"Šedohnědá"},
        {id:9, src:strecha1_vinova, caption:"Vínová"},
        {id:10, src:strecha1_zelena, caption:"Zelená"}
    ],
    [
        {id:0, src:strecha2_antracit, caption:"Antracit"},
        {id:1, src:strecha2_bila, caption:"Bilá"},
        {id:2, src:strecha2_sedobila, caption:"Šedobílá"},
        {id:3, src:strecha2_hnedatmava, caption:"Hněda Tmavá"},
        {id:4, src:strecha2_hneda, caption:"Hnědá"},
        {id:5, src:strecha2_hnedacervena, caption:"Hnědočervená"},
        {id:6, src:strecha2_modra, caption:"Modrá"},
        {id:7, src:strecha2_piskova, caption:"Písková"},
        {id:8, src:strecha2_sedohneda, caption:"Šedohnědá"},
        {id:9, src:strecha2_vinova, caption:"Vínová"},
        {id:10, src:strecha2_zelena, caption:"Zelená"}
    ],
    [
        {id:0, src:dark, caption:"dark"},
        {id:1, src:bright, caption:"bright"},
    ]
]