import React, {useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { changeProperty } from '../../actions'
import ImageListInput from '../inputs/ImageListInput'
import MaterialInput from '../inputs/MaterialInput'
import SelectInput from '../inputs/SelectInput'
import NumberInput from '../inputs/NumberInput'
import SettingsItem from './SettingsItem'
import Row from '../inputs/Row'
import { GateTypes } from '../../logic/GateTypes'
import { GatePositions } from '../../logic/GatePositions'
import { useSelector } from 'react-redux';

function Gate(props) {
    const dispatch = useDispatch();
    const parameters = useSelector(state => state.parameters);

    useEffect(() => {
        dispatch(changeProperty("gate", "materialType", 1));
        dispatch(changeProperty("gate", "material", 3));
        dispatch(changeProperty("gate", "width", 2.5));
        dispatch(changeProperty("gate", "model", 1));
        dispatch(changeProperty("gate", "motor", 0));
    },[])

    const getSizes = () => {
        const sizes = [{
            value: 0,
            text: "Bez Pohonu"
        },{
            value: 1,
            text: "ProMatic"
        }, {
            value: 2,
            text: "SupraMatic"
        }];

        return sizes;
    }

    const getModels = () => {
        const sizes = [{
            value: 1,
            text: "Renomatic (M/WoodGrain/DecoColor)"
        }, {
            value: 2,
            text: "Renomatic (L/Planar/Decocolor)"
        }];

        return sizes;
    }

    const handleTypeChange = (id) => {
        dispatch(changeProperty("gate", "type", id));
        dispatch(changeProperty("gate", "width", 2.5));
        dispatch(changeProperty("gate", "motor", 0));
        dispatch(changeProperty("gate", "insulation", false));
    }

    const handlePositionChange = (id) => {
        dispatch(changeProperty("gate", "position", id));
    }

    const handleMotorChange = (type) => {
        dispatch(changeProperty("gate", "motor", type));
    }

    const handleModelChange = (type) => {
        dispatch(changeProperty("gate", "model", type));
    }

    const handleWidthChange = (value) => {
        dispatch(changeProperty("gate", "width", value))
    }

    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked(!checked);
        dispatch(changeProperty("gate", "insulation", !checked));
    };

    return (
        <div className="Gate">
            <SettingsItem header="Vrata">
            { parameters.gate.type == 1 &&
                <h5>POZOR: Máte zvoleny sekční vrata. Musí se navýšit výška stavby a výsledná cena tedy bude o něco vyšší.</h5>
            }
                <ImageListInput
                    label="Typ vrat"
                    items={GateTypes}
                    onChange={handleTypeChange}
                ></ImageListInput>
                <ImageListInput
                    label="Pozice vrat (Ilustrativní)"
                    items={GatePositions}
                    onChange={handlePositionChange}
                ></ImageListInput>
                {/* <MaterialInput
                    onChange={handleMaterialChange}
                ></MaterialInput> */}

            { parameters.gate.type == 0 &&
                <div style={{ display: "flex" }}>
                    <label>
                        <input type="checkbox" checked={checked}
                            onChange={handleChange} />
                        <b>Zateplení výklopné brány</b>
                    </label>
                </div>
            }
            { parameters.gate.type == 1 &&
                <div style={{ display: "flex" }}>
                    <h5>Sekční vrata mají zateplení v ceně.</h5>
                </div>
            }

                <Row label="Šířka vrat">
                    <NumberInput
                        label="Šířka"
                        unit="m"
                        min={2.5}
                        max={parameters.gate.type == 0 ? 3 : 5}
                        step={0.5}
                        value={2.5}
                        onChange={handleWidthChange}
                    ></NumberInput>
                    {/* <NumberInput
                        label="Výška"
                        unit="m"
                        min={1.9}
                        max={props.garage.height_min}
                        step={0.1}
                        value={1.9}
                        onChange={handleHeightChange}
                    ></NumberInput> */}
                </Row>

                { parameters.gate.type == 1 &&
                <>
                <Row>
                    <SelectInput
                        label="Model Sekčních vrat"
                        width="200px"
                        options={getModels()}
                        onChange={handleModelChange}
                    ></SelectInput>
                </Row>
               

                <Row label="Elektrické Otevírání">
                    <SelectInput
                        label="Pohon"
                        width="200px"
                        options={getSizes()}
                        onChange={handleMotorChange}
                    ></SelectInput>
                </Row>
                </>
                 }

                <h5>Umístění vrat a barva je ilustrativní - přesné umístění je v závislosti na variantě a skladbě stěn. Jiná výklopná vrata a sekční vrata se naceňují zvlášť podle požadavku.</h5>
            </SettingsItem>
        </div>
    )
}

export default Gate
