class Parameters
{
    constructor()
    {
        this.garage = {
            width: 2.9,
            length: 5.02,
            height: 2.54,
            height_min: 2.1,
            height_max: 2.8,
            materialType: 0,
            material: 0
        };
        this.roof = {
            type: 4,
            materialType: 0,
            material: 0
        };
        this.gate = {
            type: 0,
            position: 1,
            width: 2,
            height: 1.9,
            materialType: 0,
            material: 0
        };
        this.doors = [];
        this.windows = [];
    }
}

export default Parameters;