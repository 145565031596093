import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { changeProperty } from '../../actions'
import MaterialInput from '../inputs/MaterialInput'
import SettingsItem from './SettingsItem'
import {WallTypes} from '../../logic/WallTypes';
import ImageListInput from '../../compontents/inputs/ImageListInput'

function Walls() {
    const dispatch = useDispatch();
    const [color, setColor] = useState(0);

    const handleMaterialChange = (color) => {
        dispatch(changeProperty("garage", "materialType", 1));
        dispatch(changeProperty("garage", "material", color));
    }  

    return (
        <div className="Walls">
            <SettingsItem header="Omítka">
                <h5>Omítka je bez příplatku a je součástí základní ceny zvolené stavby.</h5>
            <ImageListInput
            label=""
            items={WallTypes[1]}
            value={0}
            onChange={handleMaterialChange}
        ></ImageListInput>
            </SettingsItem>
        </div>
    )
}

export default Walls
