import React, { useEffect, useRef, useState } from 'react'
import Parameters from '../logic/Parameters'
import Visualisation from '../logic/Visualisation'
import WarningSystem from '../logic/WarningSystem'
import './Display.css'
import uuid from 'react-uuid'
import readXlsxFile from 'read-excel-file'
import Swal from 'sweetalert2'

function Display(props) {
    const display = useRef(null);
    const vis = useRef(null);
    const [infoItems, setInfoItems] = useState([]);
    const [pricing, setPricing] = useState([]);
    const [building, setBuilding] = useState();
    const [extras, setExtras] = useState(0);

    useEffect(() => {
        vis.current = new Visualisation();
        vis.current.init(display.current, new Parameters());

        //Fetch prices from excel document
        (async () => {
            const url = "cenik.xlsm";
            const data = await (await fetch(url))

            //Parse garage pricings
            readXlsxFile(data).then((rows) => {

                //Parse excel lines
                let items = []
                let currentRoof = ""
                let currentParking = ""
                rows.forEach(element => {
                    try {
                        if (element[0].includes("PULTOVÁ"))
                            currentRoof = "Pultova"
                        if (element[0].includes("SEDLOVÁ"))
                            currentRoof = "Sedlova"
                        if (element[0].includes("1 STÁNÍ"))
                            currentParking = 1
                        if (element[0].includes("2 STÁNÍ"))
                            currentParking = 2
                        let object = {}
                        object.symbol = element[0]
                        object.length = element[1]
                        object.width = element[2]
                        object.height = element[3]
                        object.price = element[5]
                        object.price_insulation = element[9]
                        object.roof = currentRoof
                        object.parkingSpots = currentParking
                        if (object.symbol != null && !element[0].includes("STŘECHA") && !element[0].includes("SYMBOL"))
                            items.push(object)
                    } catch (error) { }
                });

                //Set the pricing list
                //console.log(items)
                setPricing(items)

                //Find unique dimensions
                const uniqueWidths = [...new Set(items.map(item => item.width))];
                const uniqueLength = [...new Set(items.map(item => item.length))];
                const uniqueHeights = [...new Set(items.map(item => item.height))];
                //console.log("Unique items set:", uniqueWidths, uniqueLength, uniqueHeights);
                window.uniqueDimensions = [uniqueWidths, uniqueLength, uniqueHeights];

                //Set the first garage as default
                setBuilding(items[0])
            })
        })();

    }, []);

    function getPrice(input) {
        var numVal1 = input;
        var numVal2 = 20; //THIS IS A PERCENTAGE OF THE DISCOUNT !!!!
        var totalValue = numVal1 * ((100 - numVal2) / 100)
        return totalValue;
    }

    useEffect(() => {
        let ws = new WarningSystem();
        setInfoItems(ws.getWarnings(props.parameters).map(w => <div style={{color: "red", fontSize: "18px", alignContent: "center"}} key={uuid()}>Špatná Konfigurace: {w}</div>));
        vis.current.update(props.parameters);
        doPricing(props.parameters)
    }, [props.parameters, pricing]);

    const doPricing = (input) => {
        //Step1: Calculate garage basis price
        //console.log(input)
        //Find the appropriate garage in the databases
        setExtras(0)
        console.log(input)

        let roof = "Sedlova"
        if (input.roof.type == 4)
            roof = "Pultova"
        var result = pricing.filter(obj => {
            return (
                obj.width === input.garage.width * 1000 &&
                obj.length === input.garage.length * 1000 &&
                obj.roof === roof
            )
        })

        //console.log(result)
        setBuilding(result[0])
        //Calculate extras
        calculateExtra(input, result[0])

        window.building = result[0]
        //console.log(input)

    }

    const calculateExtra = (input, garage) => {
        let extra = 0

        //Strecha
        if (input.roof.materialType == 3) {
            let area = input.garage.width * input.garage.length
            console.log(area)
            extra += area * 90
        }

        //Dveře
        input.doors.forEach(element => {
            if (element.insulation)
                extra += 13028
            else
                extra += 10980
        });

        //Okna / Svetlik
        input.windows.forEach(element => {
            if (element.type == 0)
                extra += 1890
            else if (element.type == 1)
                extra += 2250
            else if (element.type == 2)
                extra += 2970
        });

        //Vrata
        if (input.gate.insulation)
            extra += 4500
        if (input.gate.motor == 1)
            extra += 9291
        if (input.gate.motor == 2)
            extra += 12107

        //Vyklopna
        if (input.gate.type == 0) {
            if (input.gate.width == 3)
                extra += 4100
        }
        //Sekcni
        if (input.gate.type == 1) {
            //Renomatic M
            if (input.gate.model == 1) {
                switch (input.gate.width) {
                    case 2.5:
                        extra += 14703
                        break;
                    case 3:
                        extra += 18776
                        break;
                    case 3.5:
                        extra += 20253
                        break;
                    case 4:
                        extra += 24314
                        break;
                    case 4.5:
                        extra += 31416
                        break;
                    case 5:
                        extra += 32736
                        break;
                }
            }
            //Renomatic L
            if (input.gate.model == 2) {
                switch (input.gate.width) {
                    case 2.5:
                        extra += 20082
                        break;
                    case 3:
                        extra += 22314
                        break;
                    case 3.5:
                        extra += 24755
                        break;
                    case 4:
                        extra += 27071
                        break;
                    case 4.5:
                        extra += 36882
                        break;
                    case 5:
                        extra += 36934
                        break;
                }
            }
        }

        window.extras = extra
        setExtras(extra)

        if (!props.parameters.garage.insulation)
            window.calculatedPrice = getPrice(garage?.price) + extras
        else
            window.calculatedPrice = getPrice(garage?.price) + garage?.price_insulation + extras
    }


    return (
        <>
            <div className="Display">


                <h5>Stisknutím a tažením myší můžete otáčet modelem garáže.Kolečkem myši můžete změnit přiblížení.</h5>
                <div className="Display--container" ref={display}></div>
                <div className="Display--info">
                    {infoItems}
                </div>
                <center>

                    {building?.price &&
                        <>
                            <div style={{ backgroundColor: "#568fb5", color: "white", borderRadius: "10px", width: "300px" }}>
                                {!props.parameters.garage.insulation &&
                                    <h3 style={{ marginBottom: "-15px" }}>Odhad Ceny: <b>{(getPrice(building?.price) + extras).toFixed(0).toLocaleString()} Kč</b><br></br><h6 style={{ marginTop: "-3px" }}>(Bez DPH, montáže a přepravy.)</h6></h3>
                                }
                                {props.parameters.garage.insulation &&
                                    <h3 style={{ marginBottom: "-15px" }}>Odhad Ceny: <b>{(getPrice(building?.price) + building?.price_insulation + extras).toFixed(0).toLocaleString()} Kč</b><br></br><h6 style={{ marginTop: "-3px" }}>(Bez DPH, montáže a přepravy.)</h6></h3>
                                }
                            </div>
                            <h5 style={{ color: "gray" }}>{building?.symbol} (Š:{building?.width}mm x D:{building?.length}mm x V:{building?.height}mm [P:{((building?.width / 1000 * building?.length / 1000)).toFixed(0)}m²])</h5>
                        </>
                    }
                    {!building?.price &&
                        <div>
                            <h3 style={{ color: "red", marginBottom: "-15px", backgroundColor: "#ff0000ab", borderRadius: "10px", color: "white", paddingLeft: "5px", paddingRight: "5px" }}>Zvolenou kombinaci rozměrů a střechy nemáme v nabídce.</h3>
                            <h5 style={{ color: "gray" }}>U sedlových střech je možná šířka garáže až od 2.9m.</h5>
                        </div>
                    }
                </center>
            </div>
        </>
    )
}

export default Display
